
export interface playerCount {
    player_i: number,
    count: number
}

export interface pairCount {
    count: number,
    last_num: number,
}

// 履歴
export interface resultData {
    playerCount: playerCount,
    matchCounts: number[][],
    playedPairs: { [key: string]: pairCount }
}
export class CombData {
    // 【入力情報】
    playerCnt: number; // 参加人数
    battleCnt: number; // 試合回数

    //  【計算情報】
    pairings: Set<number[]> = new Set(); // 全体のペアリングを格納する配列 [[1,2,3,4],[5,6,7,8],[9,1,5,2],[3,6,9,4]]
    pairing: number[] = []; // [1,2,3,4]
    // すでに組まれたペアを記録するセット すでに組まれたペアを記録するセット ['001-002' => 1,'003-014' => 2]
    // playedPairs: { [key: string]: number } = {};

    // ['001-002' => {count: 1, last_num: 1},'003-014' => 2]
    playedPairs: { [key: string]: pairCount } = {}
    matchCounts: number[][]; // 【②】対戦結果カウント
    //  【計算結果履歴】試合毎のマトリクスカウント
    resultMatches: number[][][] = [];

    playCounts: playerCount[];
    resultPlayCounts: playerCount[][] = [];
    results: resultData[] = [];
    constructor(playerCnt: number, battleCnt: number) {
        this.playerCnt = playerCnt;
        this.battleCnt = battleCnt;
        this.playCounts = Array.from({ length: playerCnt }, (_, i: number): playerCount => { return { player_i: i, count: 0 }; });
        this.matchCounts = Array.from({ length: playerCnt }, (): number[] => Array(playerCnt).fill(0));
    }
}
