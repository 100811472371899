import { CombData, pairCount, playerCount } from "./CombData";

export interface rateCount {
    player_i: number,
    count1: number,
    count2: number,
    count3: number,
    count4: number,
    count5: number,
    count6: number,
    pair_cnt: number,
    after_pairing: number[],//対戦相手の入れ替えを行なった後の順番
}
export class Combination {
    combData: CombData;
    disablePlayers: number[]; // 除外する人のindexリスト

    constructor(combData: CombData) {
        this.combData = combData;
        this.disablePlayers = [];
    }

    // ペアキーの作成関数
    getNum = (num: number) => ('000' + num).slice(-3);
    getPairKey = (a: number, b: number) => `${this.getNum(Math.min(a, b))}-${this.getNum(Math.max(a, b))}`;
    deepCopy = (array: number[][]) => array.map(innerArray => [...innerArray]);
    deepCopyPlayerCount = (array: playerCount[]) => JSON.parse(JSON.stringify(array));
    // generateArray = (num: number): number[] => Array.from({ length: num }, (_, i) => i);
    getSliceArray = (array: any[], endIndex: number) => array.slice(0, endIndex);

    // 除外する人を抜いた配列を取得する
    getPlayers = (playerCounts: playerCount[], disablePlayers: number[]): playerCount[] => {
        var newList = [];

        for (const playerCount of playerCounts) {
            if (disablePlayers.includes(playerCount.player_i)) { continue; }
            newList.push(playerCount);
        }
        return newList;
    };

    // 数字の配列から差を出す
    getNumbersDiff = (array: number[]): number => {
        if (array.length === 0) {
            return 0;
        }

        if (array.length === 1) {
            return array[0];
        }

        var sort = array.sort((a, b) => {
            return a - b;
        });
        const min: number = sort[0];
        const max: number = sort[sort.length - 1];
        return max - min;
    }

    // 最少と最大を出す
    getPairMinMax = (rateCounts: rateCount[], key: string): { min: number, max: number } => {
        if (!(key in rateCounts[0])) {
            console.log("指定されたキーが存在しません");
            return { min: 0, max: 0 };
        }

        const _key = key as keyof rateCount;
        var sort = rateCounts
            .filter(item => typeof item[_key] === 'number')
            .sort((a, b) => {
                return (a[_key] as number) - (b[_key] as number);
            });

        if (sort.length === 0) {
            throw new Error(`number以外の肩が使用されてます: ${_key}`);
        }
        const min: number = sort[0][_key] as number;
        const max: number = sort[sort.length - 1][_key] as number;
        return {
            min: min,
            max: max
        };
    }

    sort = (numbers: number[]) => { return numbers.sort((a, b) => { return a - b; }); }

    sortPlayerCounts = (playCounts: playerCount[]) => {
        return playCounts.sort((a, b) => {
            if (a.count !== b.count) { // 回数が違う場合
                return a.count - b.count; // 回数で昇順ソート
            } else {
                return a.player_i - b.player_i; // 回数が同じ場合はplayer_iで昇順ソート
            }
        });
    }

    sortRateCounts = (rateCounts: rateCount[]) => {
        return rateCounts.sort((a, b) => {
            const countsA = [a.count1, a.count2, a.count3, a.count4]; // countを配列でまとめる
            const countsB = [b.count1, b.count2, b.count3, b.count4];

            for (let i = 0; i < countsA.length; i++) {
                if (countsA[i] !== countsB[i]) {
                    return countsA[i] - countsB[i]; // 各カウントを比較
                }
            }

            // 全てのカウントが同じ場合、player_iでソート
            return a.player_i - b.player_i;
        });
    }


    /**
     * 計算を開始するメソッド
     */
    calMatchese(): CombData {
        for (let i = 0; i < this.combData.battleCnt * 4; i++) {
            this.step();
        }
        if (this.combData.pairing.length > 0) { // 調査用
            this.combData.pairings.add([...this.combData.pairing]); // 対戦リストに追加する
        }

        return this.combData;
    }

    /**
     * 途中から計算を開始するメソッド
     */
    reCalMatches(nowBattleCnt: number, addPlayerCnt: number, disablePlayers: number[]): CombData {
        // this.combData.playedPairs = {};// 応急処理　ペアカウントを空にする
        this.combData.playedPairs = this.combData.results[nowBattleCnt - 1].playedPairs;
        this.combData.results = this.getSliceArray([...this.combData.results], nowBattleCnt);

        var initPlayerCnt = this.combData.playerCnt;

        this.disablePlayers = disablePlayers; // 除外する人定義 

        // 人数を追加する
        this.combData.playerCnt = this.combData.playerCnt + addPlayerCnt;
        // 一旦,試合回数は同じとする
        this.combData.pairings = new Set(this.getSliceArray([...Array.from(this.combData.pairings)], nowBattleCnt));
        this.combData.playCounts = this.getSliceArray([...this.combData.playCounts], nowBattleCnt);

        this.combData.resultMatches = this.getSliceArray([...this.combData.resultMatches], nowBattleCnt);
        this.combData.matchCounts = [...this.combData.resultMatches[nowBattleCnt - 1]];
        // 新規の人で組まないように最大の値を入れている
        var match_0_index: number[] = [...this.combData.matchCounts[0]];
        match_0_index = match_0_index.slice(1, match_0_index.length - 1);
        var match_max: number = Math.max(...match_0_index);
        var initMatch = Array.from({ length: this.combData.playerCnt }, (): number[] => Array(this.combData.playerCnt).fill(0));

        //一番最後の試合のデータを代入する
        this.combData.resultPlayCounts = this.getSliceArray([...this.combData.resultPlayCounts], nowBattleCnt);
        this.combData.playCounts = this.deepCopyPlayerCount([...this.combData.resultPlayCounts[nowBattleCnt - 1]]);

        //　最少の値のカウントを追加する
        var _joinPlayerCounts = this.getPlayers(this.combData.playCounts, this.disablePlayers);// 最少の試合回数を取得
        _joinPlayerCounts = this.sortPlayerCounts(_joinPlayerCounts);
        var max = _joinPlayerCounts[_joinPlayerCounts.length - 1].count;

        for (let i = 0; i < addPlayerCnt; i++) {
            const playerCount: playerCount = { player_i: this.combData.playerCnt + i - addPlayerCnt, count: max - 1 }
            this.combData.playCounts.push(playerCount);
        }

        for (let i = 0; i < this.combData.playerCnt; i++) {
            for (let j = 0; j < this.combData.playerCnt; j++) {
                if (i >= initPlayerCnt && j >= initPlayerCnt) {
                    initMatch[i][j] = match_max; // 追加の人同士はなるべく組まないように
                } else {
                    initMatch[i][j] = this.combData.matchCounts?.[i]?.[j] ?? match_max - 1; // 範囲外は0
                }
            }
        }
        this.combData.matchCounts = initMatch;
        for (let i = 0; i < (this.combData.battleCnt - nowBattleCnt) * 4; i++) {
            this.step();
        }

        this.disablePlayers = [];// 終わった後は、初期化しておく
        return this.combData;
    }

    // 1人ずつ対戦相手を決める処理
    step(): void {
        // 現時点の適した人を追加する
        const matchPlayer_i = this.matchPair();

        // 【②】対戦結果カウントを増やす
        for (var i = 0; i < this.combData.pairing.length - 1; i++) {
            this.combData.matchCounts[this.combData.pairing[i]][matchPlayer_i] += 1;
            this.combData.matchCounts[matchPlayer_i][this.combData.pairing[i]] += 1;
        }

        if (this.combData.pairing.length >= 4) {
            this.combData.resultMatches.push(this.deepCopy(this.combData.matchCounts));
            this.combData.resultPlayCounts.push(this.deepCopyPlayerCount(this.combData.playCounts));

            // ペアを格納する
            this.addPair(this.getPairKey(this.combData.pairing[0], this.combData.pairing[1]));
            this.addPair(this.getPairKey(this.combData.pairing[2], this.combData.pairing[3]));

            this.combData.results.push({
                playerCount: this.deepCopyPlayerCount(this.combData.playCounts),
                matchCounts: this.deepCopy(this.combData.matchCounts),
                playedPairs: structuredClone(this.combData.playedPairs)
            });

            this.combData.pairings.add([this.combData.pairing[0] + 0, this.combData.pairing[1] + 0, this.combData.pairing[2] + 0, this.combData.pairing[3] + 0]); // 対戦リストに追加する
            this.combData.pairing = []; // 空にする
        }
    }

    // 最少の数を元に対戦相手を決める(再起処理)
    matchPair(): number {
        // 不参加の人は除外
        var _joinPlayerCounts = this.getPlayers(this.combData.playCounts, this.disablePlayers);
        // 既に選択中の人は除外
        _joinPlayerCounts = this.getPlayers(_joinPlayerCounts, this.combData.pairing);
        // 前回入った人は除外（10人目から）
        var _pairings = [...Array.from(this.combData.pairings)];
        var _playerCnt = this.combData.playerCnt - this.disablePlayers.length;
        if (_pairings.length > 0 && _playerCnt > 9) {
            _joinPlayerCounts = this.getPlayers(_joinPlayerCounts, _pairings[_pairings.length - 1]);
        }

        const _rateCounts = this.sortRatePlayer(_joinPlayerCounts);

        var newList: rateCount[] = this.filterRateCounts(_rateCounts);

        if (_rateCounts.length > 0) { // 不参加、前回対戦、既に選択中以外で残り０人
            var selectPlayer_i = 0;
            if (newList.length > 0) { //　ペア、試合回数の差が最小から2を超える場合
                selectPlayer_i = newList[0].player_i;
                this.combData.pairing = newList[0].after_pairing;
            } else {
                selectPlayer_i = _rateCounts[0].player_i;
                this.combData.pairing = _rateCounts[0].after_pairing;
            }
            this.combData.playCounts[selectPlayer_i].count += 1; //対戦回数を増やす

            return selectPlayer_i; // 対戦者が１人確定する
        } else {
            throw new Error('除外後に0人');
        }
    }

    /**
     * 参加者の順番を評価値順に並び替える
     */
    sortRatePlayer(joinPlayerCounts: playerCount[]): rateCount[] {
        var rateList: rateCount[] = []; // 初期値として対戦回数を入れる

        // 既に決まっている対戦相手から評価値を計算する。
        for (const playerCounts of joinPlayerCounts) {
            var pairCnt = 0;
            var ratingValues = []; // 差を出すために配列に入れてから後で計算
            // 2人目は以降は対戦する人のバランスも評価に含める
            for (const player_i of this.combData.pairing) {
                const matchCount = this.combData.matchCounts[player_i][playerCounts.player_i];
                ratingValues.push(matchCount === 0 ? -5 : matchCount); // 0回目のペアの評価値を高めに
            }
            const ratingSum = ratingValues.reduce((a, b) => a + b, 0);

            // ③ペアチェック 入れ替えた時に一番小さい値にする
            var swapPairCount = this.getSwapPairCount(this.combData.pairing, playerCounts.player_i);

            rateList.push({
                player_i: playerCounts.player_i,
                count1: playerCounts.count, // 試合回数
                count2: swapPairCount.pairCount.count, // 入れ替えた時に一番少ないペア合計
                count3: swapPairCount.pairCount.last_num,// ペアの直近の試合番号（3試合目で戦ったペアは3を格納）
                count4: playerCounts.count + ratingSum, // 試合回数 + 評価値
                count5: this.getNumbersDiff(ratingValues), // 各評価値の差
                count6: ratingValues.length == 0 ? 0 : Math.max(...(ratingValues)), // 評価値 必要ないがスキップする用　一番大きい数がmax値の場合除外
                pair_cnt: pairCnt, // ペアの回数 必要ないがスキップする用
                after_pairing: swapPairCount.after_pairing // 配列
            });
        }

        //　評価が高い順(数値が低い)でソートして返却
        return this.sortRateCounts(rateList);
    }

    // ペアを追加する
    addPair(pairKey: string) {
        const last_num = this.combData.pairings.size;
        if (pairKey in this.combData.playedPairs) {
            this.combData.playedPairs[pairKey].count += 1;
            this.combData.playedPairs[pairKey].last_num = last_num;
        } else {
            this.combData.playedPairs[pairKey] = { count: 1, last_num: last_num };
        }
    }

    // 入れ替えて一番少ない値を返却する TODO:最終的にペアを入れ替える
    getSwapPairCount(pairing: number[], player2: number): { pairCount: pairCount, after_pairing: number[] } {
        var pairs: number[][] = this.generatePairs(pairing, player2);
        const pairCnts = pairs.map(pair => {
            return {
                pairCount: this.calculatePairCount(pair),
                after_pairing: pair,
            };
        });
        pairCnts.sort((a, b) => {
            if (a.pairCount.count !== b.pairCount.count) { // 回数が違う場合
                return a.pairCount.count - b.pairCount.count; // 回数で昇順ソート
            } else {
                return a.pairCount.last_num - b.pairCount.last_num;
            }
        });
        return pairCnts[0];
    }

    //【 getSwapPairCount 用】 入れ替え用のペア配列を生成
    private generatePairs(pairing: number[], player2: number): number[][] {
        if (pairing.length === 0) {
            return [[player2]];
        }
        else if (pairing.length === 1) { // [1-2]
            return [[pairing[0], player2]];
        } else if (pairing.length === 2) { // [1,2,3] [1,2]と[1-3]の最低値
            return [
                [pairing[0], pairing[1], player2],
                [pairing[0], player2, pairing[1]]
            ];
        } else if (pairing.length === 3) {// [1,2,3,4] [1-2][3-4]と[1-3][2-4]と[1-4][2-3]の最低値
            return [
                [pairing[0], pairing[1], pairing[2], player2],
                [pairing[0], pairing[2], pairing[1], player2],
                [pairing[0], player2, pairing[1], pairing[2]]
            ];
        } else {
            throw new Error(`エラー:pairing.length例外${pairing}`);
        }
    }

    //【getSwapPairCount用】 ペアの回数を呼び出す
    private calculatePairCount(pair: number[]): pairCount {
        const pairKey = this.getPairKey(pair[0], pair[1]);
        const pairCnt = { count: this.combData.playedPairs[pairKey]?.count || 0, last_num: this.combData.playedPairs[pairKey]?.last_num || 0 }; // ない場合は0 処理を返す

        if (pair.length === 4) { // 4人目の場合は
            const pairKey2 = this.getPairKey(pair[2], pair[3]);
            pairCnt.count += (this.combData.playedPairs[pairKey2]?.count || 0);
            pairCnt.last_num += (this.combData.playedPairs[pairKey2]?.last_num || 0);
        }

        return pairCnt;
    }


    // 【matchPair用】ペア数と試合回数が2以上ある場合場外
    filterRateCounts(_rateCounts: rateCount[]): rateCount[] {
        var result = this.filterByCountDiff(_rateCounts, 'count1', 2);
        var result2 = this.filterByCountDiff(result, 'count2', 2);
        // var result3 = this.filterByCountDiff(result2, 'count6', 2);　//　スキップは他の要素に影響が強いため中止
        return result2;
    }

    // 【filterRateCounts用】
    filterByCountDiff(rateCounts: rateCount[], countKey: keyof rateCount, diffNum: number): rateCount[] {
        const newList: rateCount[] = [];
        // var minMax = { min: 0, max: 0 };
        // if (countKey === 'count6') {
        //     minMax = { min: Math.min(...this.combData.matchCounts.flat()), max: Math.max(...this.combData.matchCounts.flat()) };
        // } else {
        // }
        var minMax = this.getPairMinMax(rateCounts, countKey);

        for (const rateCount of rateCounts) {
            if (minMax.max - minMax.min >= diffNum && (minMax.max <= (rateCount[countKey] as number))) {
                continue; // 最大-最小が2以上の場合の最大値は除外(2-0の場合は2が除外)
            }
            newList.push(rateCount);
        }

        return newList;
    }
}
