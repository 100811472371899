// import React, { useState } from 'react';
import '../css/App.css';
import Home from "./Home";
import List from "./List";
import { Route, Routes, BrowserRouter } from "react-router-dom";
// import './combination.js';

function App() {
	return (
		<BrowserRouter>
			<Routes> {/*Routesで囲む*/}
				<Route path="/" element={<Home />} /> {/*RouteにHomeを設定する*/}
				<Route path="/list" element={<List />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
