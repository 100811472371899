import { ButtonHTMLAttributes } from "react";


export default function Button({
    className = 'bg-neutral-600',
    children,
    onClick,
    ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <button
            className={`${className} lg:text-3xl lg:h-16 px-3 h-8 text-white rounded-md text-sm font-bold`}
            onClick={onClick}
        >
            {children}
        </button>
    );
}
